html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto;
  height: 100%;
  background-color: rgba(246, 247, 248, 0.5);
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body.dark-mode {
  background-color: inherit;
}
