.hexNameContainer {
  stroke: white;
  fill: white;
}

:global(.dark-mode) .hexNameContainer {
  stroke: black;
  fill: black;
}

.visibleOnHover {
  display: none;
}

.visibleOnHover:hover {
  display: inherit;
}
